import { App as AppToListen, URLOpenListenerEvent } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { IonApp, IonLoading, IonRouterOutlet, setupConfig } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { AFInit, AppsFlyer } from 'appsflyer-capacitor-plugin'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Redirect, Route } from 'react-router-dom'
import AuthRoute from './components/AuthRoute'
import EnvCheckErrorBoundary from './components/EnvCheckErrorBoundary'
import Message from './components/JoinParty/Message'
import Modal from './components/JoinParty/Modal'
import ModalTitle from './components/JoinParty/ModalTitle'
import ModalBackgroundComponent from './components/Modal/ModalBackgroundComponent'
import PartySubscriptionWrapper from './components/PartySubscriptionWrapper'
import { APPSYFLYER_DEV_KEY, APPSYFLYER_IOS_ID, APP_VERSION } from './config'
import UIContext from './context'
import { EventBus } from './eventBus'
import './main.css'
import * as routes from './routes'
import {
  READ_BUCKET_EMPTY_EVENT,
  READ_BUCKET_NOT_EMPTY_EVENT,
  WRITE_BUCKET_EMPTY_EVENT,
} from './services/firebaseService'
import { MaintenanceService } from './services/maintenanceService'
import { VersionService } from './services/versionService'
/* Theme variables */
import './theme/variables.css'
import { useUserContext } from './userContext'
import {
  mixpanelWrapper,
  runAppsFlyer,
  runOneSignal,
  sentryWrapper,
} from './util'

const MainTabs = lazy(() => import('./MainTabs'))
const ErrorPage = lazy(() => import('./pages/ErrorPage'))
const VideoTutorialPage = lazy(() => import('./pages/VideoTutorialPage'))
const GreetingPage = lazy(() => import('./pages/GreetingPage'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const LogoutPage = lazy(() => import('./pages/LogoutPage'))
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'))
const SignupPage = lazy(() => import('./pages/SignupPage'))
const StartPage = lazy(() => import('./pages/StartPage'))
const CityPickPage = lazy(() => import('./pages/CityPickPage'))
const ChallengePageWrapper = lazy(
  () => import('./pages/challenge/ChallengePageWrapper')
)
const CreatePartyPageWrapper = lazy(
  () => import('./pages/createPartyPage/CreatePartyPageWrapper')
)
const CreateSoloPartyPage = lazy(() => import('./pages/CreateSoloPartyPage'))
const DailyChallengePage = lazy(() => import('./pages/DailyChallengePage'))
const DailyChallengeResultsPage = lazy(
  () => import('./pages/DailyChallengeResultsPage')
)
const PartyOptionPage = lazy(() => import('./pages/PartyOptionPage'))
const PartyWaitingPage = lazy(() => import('./pages/PartyWaitingPage'))
const ScourLoadingPage = lazy(() => import('./pages/ScourLoadingPage'))
const ScourPaymentPage = lazy(() => import('./pages/ScourPaymentPage'))
const ScourSummaryPage = lazy(() => import('./pages/ScourSummaryPage'))
const StopMapPageWrapper = lazy(
  () => import('./pages/stopMapPage/StopMapPageWrapper')
)
const StopPage = lazy(() => import('./pages/StopPage'))

//@ts-ignore
//without next lines mapbox doesn't work on some android devices
//eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

setupConfig({
  swipeBackEnabled: false,
  animated: false,
  mode: 'ios',
})

const App: React.FC = () => {
  const { user: authUser, currentUser: user } = useUserContext()
  const authenticated = authUser !== null

  const [readBucketEmpty, setReadBucketEmpty] = useState(false)
  const [writeBucketEmpty, setWriteBucketEmpty] = useState(false)
  const showqueriesError = readBucketEmpty || writeBucketEmpty

  const [clientSupported, setClientSupported] = useState(true)
  const [maintenanceMode, setMaintenanceMode] = useState(false)

  const { setScoursCoupon, setCityCoupon } = useContext(UIContext)

  useEffect(() => {
    runOneSignal((OneSignal) =>
      OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_ID || '')
    )
  }, [])

  useEffect(() => {
    if (authUser === null) return
    runOneSignal((OneSignal) => OneSignal.setExternalUserId(authUser.uid))
  }, [authUser])

  useEffect(() => {
    runOneSignal((OneSignal) =>
      OneSignal.addTrigger('authenticated', authenticated ? 'true' : 'false')
    )

    const handle = authenticated
      ? setInterval(() => {
          runOneSignal((OneSignal) => {
            OneSignal.getTriggerValueForKey(
              'authenticated_session_duration',
              (value: string | null) => {
                const valueNumber = Number(value)
                const currentAuthenticatedSessionDuration = Number.isNaN(
                  valueNumber
                )
                  ? 0
                  : valueNumber
                const authenticatedSessionDuration =
                  currentAuthenticatedSessionDuration + 1

                OneSignal.addTrigger(
                  'authenticated_session_duration',
                  authenticatedSessionDuration.toString()
                )
              }
            )
          })
        }, 1000)
      : null

    return () => {
      handle && clearInterval(handle)
    }
  }, [authenticated])

  useEffect(() => {
    const loadData = async () => {
      const isMaintenanceMode = await MaintenanceService.getIsMaintenanceMode()
      setMaintenanceMode(isMaintenanceMode)
    }

    loadData()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      const isSupported = await VersionService.getIsClientSupported()
      setClientSupported(isSupported)
    }

    loadData()
  }, [])

  useEffect(() => {
    if (APPSYFLYER_IOS_ID && APPSYFLYER_DEV_KEY) {
      const afConfig: AFInit = {
        appID: APPSYFLYER_IOS_ID,
        devKey: APPSYFLYER_DEV_KEY,
      }

      runAppsFlyer(() => AppsFlyer.initSDK(afConfig))
    }
  }, [])

  useEffect(() => {
    if (authUser === null) return
    runAppsFlyer(() => AppsFlyer.setCustomerUserId({ cuid: authUser.uid }))
  }, [authUser])

  useEffect(() => {
    const readBucketEmptyListener = () => setReadBucketEmpty(true)
    EventBus.subscribe(READ_BUCKET_EMPTY_EVENT, readBucketEmptyListener)

    const writeBucketEmptyListener = () => setWriteBucketEmpty(true)
    EventBus.subscribe(WRITE_BUCKET_EMPTY_EVENT, writeBucketEmptyListener)

    const readBucketNotEmptyListener = () => setReadBucketEmpty(false)
    EventBus.subscribe(READ_BUCKET_NOT_EMPTY_EVENT, readBucketNotEmptyListener)

    const writeBucketNotEmptyListener = () => setWriteBucketEmpty(false)
    EventBus.subscribe(READ_BUCKET_NOT_EMPTY_EVENT, writeBucketNotEmptyListener)

    return () => {
      EventBus.unsubscribe(READ_BUCKET_EMPTY_EVENT, readBucketEmptyListener)
      EventBus.unsubscribe(WRITE_BUCKET_EMPTY_EVENT, writeBucketEmptyListener)
      EventBus.unsubscribe(
        READ_BUCKET_NOT_EMPTY_EVENT,
        readBucketNotEmptyListener
      )
      EventBus.unsubscribe(
        READ_BUCKET_NOT_EMPTY_EVENT,
        writeBucketNotEmptyListener
      )
    }
  }, [])

  useEffect(() => {
    if (!user) return

    const setDeviceData = async () => {
      const [device, deviceId] = await Promise.all([
        Device.getInfo(),
        Device.getId(),
      ])

      mixpanelWrapper().people.set({
        'App Version': APP_VERSION,
        'Device Name': device.name,
        'Device Model': device.model,
        'Device OS': device.operatingSystem,
        'Device OS Version': device.osVersion,
        'Device Manufacturer': device.manufacturer,
        'Device UUID': deviceId.uuid,
        'User ID': user.id,
      })
    }

    mixpanelWrapper().identify(user.id)
    sentryWrapper().setUser({
      id: user.id,
      username: user.username,
      email: user.email || undefined,
      ip_address: '{{auto}}',
    })
    setDeviceData()
  }, [user?.id])

  useEffect(() => {
    AppToListen.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      checkSlug(event.url)
    })
    AppToListen.getLaunchUrl().then((value) => {
      if (value?.url) checkSlug(value.url)
    })
    return () => {
      AppToListen.removeAllListeners()
    }
  }, [])

  const checkSlug = useCallback((url: string) => {
    const slug = url.split('/app/').pop()

    if (slug?.includes('redeemedCoupon')) {
      setScoursCoupon(slug.split('redeemedCoupon-').pop() || '')
    }
    if (slug?.includes('redeemedCityCoupon')) {
      setCityCoupon(slug.split('redeemedCityCoupon-').pop() || '')
    }
  }, [])

  if (!clientSupported) {
    return (
      <ModalBackgroundComponent classnames={{ background: 'blue' }}>
        <Modal>
          <ModalTitle>Unsupported Version</ModalTitle>
          <Message theme="no-margin-bottom">
            Please update City Scour to the latest version. You are currently
            using v{VersionService.getClientVersion()}, while the minimum
            supported version is v{VersionService.getSmallestSupportedVersion()}
          </Message>
        </Modal>
      </ModalBackgroundComponent>
    )
  }

  if (maintenanceMode) {
    return (
      <ModalBackgroundComponent classnames={{ background: 'blue' }}>
        <Modal>
          <ModalTitle>City Scour Currently Unavailable</ModalTitle>
          <Message theme="no-margin-bottom">
            City Scour is currently unavailable. We are currently working on a
            fix, and apologize for any inconvenience in the mean time. Please
            check the app again soon.
          </Message>
        </Modal>
      </ModalBackgroundComponent>
    )
  }

  return (
    <>
      {showqueriesError && (
        <ModalBackgroundComponent classnames={{ background: 'blue' }}>
          <Modal>
            <Message theme="no-margin-bottom">
              Database query limit reached over, please wait...
            </Message>
          </Modal>
        </ModalBackgroundComponent>
      )}
      <PartySubscriptionWrapper />
      <IonApp>
        <EnvCheckErrorBoundary>
          <IonReactRouter>
            <Suspense fallback={<IonLoading isOpen={true} />}>
              <IonRouterOutlet>
                {/* Auth */}
                <Route exact path={routes.VIDEO_TUTORIAL}>
                  <VideoTutorialPage />
                </Route>
                <Route exact path={routes.GREETING}>
                  <GreetingPage />
                </Route>
                <Route exact path={routes.LOGIN_EMAIL}>
                  <LoginPage />
                </Route>
                <Route exact path={routes.LOGOUT}>
                  <LogoutPage />
                </Route>
                <Route exact path={routes.RESET}>
                  <ResetPasswordPage />
                </Route>
                <Route exact path={routes.SIGNUP}>
                  <SignupPage />
                </Route>
                <Route exact path={routes.START}>
                  <StartPage />
                </Route>

                {/* Scour flow */}
                <Route exact path={routes.SCOUR_PARTY_SOLO}>
                  <CreateSoloPartyPage />
                </Route>
                <Route exact path={routes.SCOUR_STOP}>
                  <StopPage />
                </Route>
                <Route exact path={routes.SCOUR_STOP_MAP}>
                  <StopMapPageWrapper />
                </Route>
                <Route exact path={routes.SCOUR_CHALLENGE}>
                  <ChallengePageWrapper />
                </Route>
                <Route exact path={routes.SCOUR_END}>
                  <ScourSummaryPage />
                </Route>
                <Route exact path={routes.SCOUR_LOADING}>
                  <ScourLoadingPage />
                </Route>
                {/* Other */}
                <Route exact path={routes.SCOUR_PARTY_WAITING}>
                  <PartyWaitingPage />
                </Route>

                <Route exact path={routes.SCOUR_PARTY_OPTION}>
                  <PartyOptionPage />
                </Route>

                <Route exact path={routes.SCOUR_PAYMENT}>
                  <ScourPaymentPage />
                </Route>

                <Route exact path={routes.SCOUR_PARTY_CREATE}>
                  <CreatePartyPageWrapper />
                </Route>

                <Route exact path={routes.DAILY_CHALLENGE_RESULTS}>
                  <DailyChallengeResultsPage />
                </Route>
                <Route exact path={routes.DAILY_CHALLENGE}>
                  <DailyChallengePage />
                </Route>
                <Route exact path={routes.CITY_PICK}>
                  <CityPickPage />
                </Route>

                {/* Tabs */}
                <AuthRoute path={routes.TABS}>
                  <MainTabs />
                </AuthRoute>
                <AuthRoute exact path={routes.ROOT}>
                  <Redirect to={routes.TABS} />
                </AuthRoute>

                <Route>
                  <ErrorPage />
                </Route>
              </IonRouterOutlet>
            </Suspense>
          </IonReactRouter>
        </EnvCheckErrorBoundary>
      </IonApp>
    </>
  )
}
export default React.memo(App)
