import { useLazyQuery } from '@apollo/client'
import {
  Mixpanel as mixpanelNative,
  MixpanelPeople as mixpanelNativePeople,
} from '@awesome-cordova-plugins/mixpanel'
import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/react'
import { AppsFlyer, AppsFlyerPlugin } from 'appsflyer-capacitor-plugin'
import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth'
import { getDistance } from 'geolib'
import { History } from 'history'
import mixpanel, { Dict } from 'mixpanel-browser'
import OneSignal from 'onesignal-cordova-plugin'
import {
  BaseSyntheticEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  ENVIRONMENT,
  MIXPANEL_TOKEN,
  REACT_APP_OVERRIDE_LOGGING,
} from './config'
import { auth } from './firebase'
import {
  Achievement,
  AchievementsDocument,
  AchievementsQuery,
  CitiesDocument,
  CitiesQuery,
  GeoPoint,
  MeQuery,
  PartiesDocument,
  PartiesQuery,
  PausedAtPage,
  PinsDocument,
  PinsQuery,
  ScourDocument,
  ScourQuery,
  ScoursDocument,
  ScoursQuery,
  TodaysDailyChallengeDocument,
  TodaysDailyChallengeQuery,
} from './generated'
import {
  doesMatch,
  populate,
  SCOUR_CHALLENGE,
  SCOUR_END,
  SCOUR_INFO,
  SCOUR_PARTY_OPTION,
  SCOUR_STOP_MAP,
} from './routes'

export function useNullableUser(): [FirebaseUser | null, boolean] {
  const currentUser = auth.currentUser
  const [user, setUser] = useState<FirebaseUser | null>(currentUser)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  return [user, loading]
}

export function useQueryAllParties(): [
  PartiesQuery['parties'] | undefined,
  () => Promise<void>
] {
  const [parties, setParties] = useState<PartiesQuery['parties']>()

  const [getParties] = useLazyQuery(PartiesDocument, {
    fetchPolicy: 'network-only',
  })

  const updateParties = useCallback(async () => {
    const res = await getParties()
    setParties(res.data?.parties)
  }, [])

  useEffect(() => {
    updateParties()
  }, [])

  return [parties, updateParties]
}

export function useQueryAchievements():
  | AchievementsQuery['achievements']
  | undefined {
  const [achievements, setAchievements] =
    useState<AchievementsQuery['achievements']>()

  const [getAchievements] = useLazyQuery(AchievementsDocument)

  useEffect(() => {
    getAchievements().then((res) => setAchievements(res.data?.achievements))
  }, [])

  return achievements
}

export function useQueryScours(): ScoursQuery['scours'] | undefined {
  const [scours, setScours] = useState<ScoursQuery['scours']>()

  const [getScours] = useLazyQuery(ScoursDocument)

  useEffect(() => {
    getScours().then((res) => setScours(res.data?.scours))
  }, [])

  return scours
}

export function useQueryAllCities(): CitiesQuery['cities'] | undefined {
  const [cities, setCities] = useState<CitiesQuery['cities']>()

  const [getCities] = useLazyQuery(CitiesDocument)

  useEffect(() => {
    getCities().then((res) => setCities(res.data?.cities))
  }, [])

  return cities
}

export function useQueryPins(): [
  PinsQuery['pins'] | undefined,
  () => Promise<void>
] {
  const [pins, setPins] = useState<PinsQuery['pins']>()

  const [getPins] = useLazyQuery(PinsDocument, {
    fetchPolicy: 'network-only',
  })

  const updatePins = async () => {
    const res = await getPins()
    setPins(res.data?.pins)
  }

  useEffect(() => {
    updatePins()
  }, [])

  return [pins, updatePins]
}

export function useQueryDailyChallenge():
  | TodaysDailyChallengeQuery['todaysDailyChallenge']
  | undefined {
  const [dailyChallenge, setDailyChallenge] =
    useState<TodaysDailyChallengeQuery['todaysDailyChallenge']>()

  const [getDailyChallenge] = useLazyQuery(TodaysDailyChallengeDocument, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!navigator.onLine) return
    getDailyChallenge().then((res) => {
      setDailyChallenge(res.data?.todaysDailyChallenge)
    })
  }, [navigator.onLine])

  return dailyChallenge
}

/**
 * Use Scour from state, or fetch from DB otherwise
 * @param id id of Scour
 */
export function useScour(
  id: string
): [ScourQuery['scour'] | null, (scourId: string) => Promise<void>] {
  const [scour, setScour] = useState<ScourQuery['scour'] | null>(null)

  const [getScour] = useLazyQuery(ScourDocument, {
    fetchPolicy: 'network-only',
  })

  const updateScour = useCallback(async (scourId: string) => {
    if (!scourId) return
    const res = await getScour({
      variables: {
        scourId,
      },
    })
    setScour(res.data?.scour)
  }, [])

  useEffect(() => {
    if (!navigator.onLine) return
    updateScour(id)
  }, [id, navigator.onLine])

  return [scour, updateScour]
}

function logBase(base: number, of: number): number {
  return Math.log(of) / Math.log(base)
}

export function calculateXpForAnswer(
  correct: boolean,
  elapsedTime: number
): number {
  const correctnessPoints = correct ? 1000 : 0
  const timePenalty = Math.round(75 * logBase(2, elapsedTime))
  const points = Math.max(0, correctnessPoints - Math.max(0, timePenalty))
  return points
}

export const sum = (a: number, b: number): number => a + b

const memoizedLevelScours = [0, 0, 1000]

export function getScoreNecessaryForLevel(lvl: number): number {
  if (memoizedLevelScours[lvl] !== undefined) {
    return memoizedLevelScours[lvl]
  }

  const previousLevelScore = getScoreNecessaryForLevel(lvl - 1)

  const score =
    (previousLevelScore - getScoreNecessaryForLevel(lvl - 2)) * 1.05 +
    previousLevelScore

  memoizedLevelScours[lvl] = score
  return score
}

export function getLevelByXP(xp: number) {
  let leftXp = xp
  let level = 1

  while (getScoreNecessaryForLevel(level + 1) < leftXp) {
    level++
    leftXp -= getScoreNecessaryForLevel(level)
  }

  return [level, leftXp]
}

export function getLevelCompletedPercentage(
  gotPoints: number,
  needPoints: number
): number {
  return Math.round((gotPoints * 100) / needPoints)
}

export function defineScourStatus(
  currentUser: MeQuery['me'],
  currentScourid: string,
  parties: PartiesQuery['parties']
): string {
  let status: string
  if (
    currentUser?.completedScours.some(
      (completedScour) => completedScour.id === currentScourid
    )
  ) {
    status = 'completed'
  } else if (
    parties.some(
      (party) =>
        party.scour.id === currentScourid &&
        party.ownerId === currentUser?.id &&
        party.paused
    )
  ) {
    status = 'paused'
  } else status = ''

  return status
}

export function defineNextPagePath(
  shouldShowInstructions: boolean,
  scourId: string
): string {
  if (shouldShowInstructions) return populate(SCOUR_INFO, { scourId })
  else return populate(SCOUR_PARTY_OPTION, { scourId })
}

export function defineChallengeNextPath(
  challengeIndex: number,
  stopIndex: number,
  challengesLength: number,
  stopsLength: number,
  scourId: string,
  partyId: string
): string {
  if (challengeIndex >= challengesLength - 1) {
    if (stopIndex < stopsLength - 1) {
      return populate(SCOUR_STOP_MAP, {
        scourId,
        partyId,
        stopIndex: stopIndex + 1,
      })
    } else {
      return populate(SCOUR_END, { scourId, partyId })
    }
  } else {
    return populate(SCOUR_CHALLENGE, {
      scourId,
      partyId,
      stopIndex,
      challengeIndex: challengeIndex + 1,
    })
  }
}

export function mixpanelWrapper() {
  const native = Capacitor.isNativePlatform()

  ;(native ? mixpanelNative : mixpanel).init(MIXPANEL_TOKEN)

  let env_check = ENVIRONMENT === 'production' || REACT_APP_OVERRIDE_LOGGING

  let actions = {
    identify: (id: string | undefined) => {
      if (env_check)
        native ? mixpanelNative.identify(id || '') : mixpanel.identify(id)
    },
    track: (name: string, props?: Dict) => {
      if (env_check)
        native ? mixpanelNative.track(name, props) : mixpanel.track(name, props)
    },
    people: {
      set: (props: Dict) => {
        if (env_check)
          native ? mixpanelNativePeople.set(props) : mixpanel.people.set(props)
      },
    },
    timeEvent: (name: string) => {
      if (env_check)
        native ? mixpanelNative.timeEvent(name) : mixpanel.time_event(name)
    },
    reset: () => {
      if (env_check) native ? mixpanelNative.reset() : mixpanel.reset()
    },
  }

  return actions
}

interface SentryActions {
  setUser: typeof Sentry.setUser
  captureMessage: typeof Sentry.captureMessage
  captureException: typeof Sentry.captureException
  configureScope: typeof Sentry.configureScope
}

export function sentryWrapper() {
  const env_check = ENVIRONMENT === 'production' || REACT_APP_OVERRIDE_LOGGING

  const actions: SentryActions = {
    setUser(...args) {
      if (env_check) Sentry.setUser(...args)
    },
    captureMessage(...args) {
      if (env_check) return Sentry.captureMessage(...args)
      return ''
    },
    captureException(...args) {
      if (env_check) return Sentry.captureException(...args)
      return ''
    },
    configureScope(...args) {
      if (env_check) return Sentry.configureScope(...args)
      return ''
    },
  }

  return actions
}

export function formatDate(date: number): string {
  const monthes = [
    'january',
    'fabruary',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ]
  const converted = new Date(date)

  return `${
    monthes[converted.getMonth()]
  } ${converted.getDate()}, ${converted.getFullYear()}`
}

export interface ExpandedAchievement extends Achievement {
  completedStops: number
  stopsAmount: number
}

export function convertMetersToMiles(distanse: number): number {
  return (distanse / 1000) * 0.621
}

export type ButtonText =
  | 'continue'
  | 'see results'
  | 'next stop'
  | "I'm here"
  | 'challenge'

export interface Params {
  scourId: string
  stopIndex: string
  challengeIndex: string
  additional: string
  resume: string
  partyId: string
  articleId: string
  stoppedAtPage: PausedAtPage
  dailyChallengeId: string
}

export interface defaultStartPageProps {
  stopIndex: number
  challengeIndex: number
  stoppedAtPage: PausedAtPage
}

export const imageStyleBeforeLoaded = {
  opacity: 0,
  height: '0px',
  width: '0px',
}

export function isNearScour(
  geolocation: GeoPoint | string | undefined,
  scour: ScourQuery['scour'] | undefined
) {
  const MILES_IN_METERS = 1609.344
  if (!geolocation || typeof geolocation === 'string' || !scour) {
    return false
  }

  const distance = getDistance(
    { lat: geolocation?.latitude ?? 0, lng: geolocation?.longitude ?? 0 },
    {
      latitude: scour.stops[0]?.location?.geolocation?.latitude ?? 0,
      longitude: scour.stops[0]?.location?.geolocation?.longitude ?? 0,
    }
  )

  return distance < MILES_IN_METERS + (geolocation as any).accuracy
}

export const handleInput = (
  event: BaseSyntheticEvent,
  setField: Dispatch<SetStateAction<string>>
) => setField(event.target.value)

export const slideOpts = {
  initialSlide: 0,
  speed: 400,
}

export const slideOptsNoSwipe = {
  initialSlide: 0,
  speed: 400,
  allowTouchMove: false,
}

export const handleSwipe = async (
  sliderEl: RefObject<HTMLIonSlidesElement>,
  setCurrentActiveButton: Dispatch<SetStateAction<number>>
) => {
  const swiper = await sliderEl?.current?.getSwiper()
  setCurrentActiveButton(swiper.activeIndex)
}

type OneSignalCallback = (os: typeof OneSignal) => any

/**
 * OneSignal can't run in the browser (window.cordova is undefined).
 * This function will only run the callback if not in the browser
 * @param callback runs if OneSignal is available (i.e. on a native platform)
 */
export function runOneSignal(callback: OneSignalCallback) {
  if (Capacitor.isNativePlatform()) {
    callback(OneSignal)
  }
}

type AppsFlyerCallback = (af: AppsFlyerPlugin) => any

export function runAppsFlyer(callback: AppsFlyerCallback) {
  if (Capacitor.isNativePlatform()) {
    callback(AppsFlyer)
  }
}

export type voidReturnFunction = () => void

export type ChallengeAnswer = {
  userId: string
  correct: boolean
  elapsedTime: number
  answer: string | number
}

export type PartyAnswer = {
  __typename?: string
  answer: {
    __typename?: string
    value: number | string
  }
  challengeId: string
  correct: boolean
  elapsedTime: number
  usedHint: boolean
  user: {
    id: string
  }
  xp: number
}

export const overwriteIonBackButton = (history: History, page: string) => {
  document.addEventListener('ionBackButton', (event: any) => {
    event.detail.register(10, () => {
      if (doesMatch(page, history.location.pathname)) {
        return
      } else {
        history.goBack()
      }
    })
  })

  return () => {
    document.removeEventListener('ionBackButton', (event: any) => {
      event.detail.register(10, () => {
        if (doesMatch(page, history.location.pathname)) {
          return
        } else {
          history.goBack()
        }
      })
    })
  }
}

export const isVideo = (url: string): boolean => {
  const parts = url.split('.')
  const last = parts[parts.length - 1].split('?')[0]
  switch (last.toLowerCase()) {
    case 'mp4':
    case 'mov':
    case 'wmv':
    case 'avi':
    case 'webm':
    case 'mkv':
    case 'flv':
    case 'ogg':
      return true
  }
  return false
}
