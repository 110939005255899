import React from 'react'
import { useUserContext } from '../userContext'
import PartySubscriptionComponent from './PartySubscriptionComponent'

const PartySubscriptionWrapper: React.FC = () => {
  const { user, currentUser } = useUserContext()

  if (user && currentUser) return <PartySubscriptionComponent />

  return null
}

export default PartySubscriptionWrapper
