import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { VIDEO_TUTORIAL } from '../routes'
import { useUserContext } from '../userContext'
import Loading from './Loading'

/**
 * Drop-in replacement for react-dom's Route component.
 * Redirects user to login page if the user is not authenticated
 */
const AuthRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { exists: userExists, shouldBeRedirected } = useUserContext()

  if (userExists === null)
    return <Loading context={{}} debugName="auth-route" />
  if (!userExists && shouldBeRedirected) return <Redirect to={VIDEO_TUTORIAL} />

  if (userExists) return <Route {...props}>{children}</Route>

  return <></>
}

export default AuthRoute
