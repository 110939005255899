import { ApolloProvider } from '@apollo/client'
import { SplashScreen } from '@capacitor/splash-screen'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { hotjar } from 'react-hotjar'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import App from './App'
import {
  DSN,
  ENVIRONMENT,
  HJID,
  HJSV,
  REACT_APP_OVERRIDE_LOGGING,
  RELEASE,
} from './config'
import { UIProvider } from './context'
import reportWebVitals from './reportWebVitals'
import { apolloService } from './services/apolloService'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { UserContextProvider } from './userContext'

const history = createBrowserHistory()

if (ENVIRONMENT === 'production' || REACT_APP_OVERRIDE_LOGGING) {
  Sentry.init({
    dsn: DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    release: RELEASE,
    attachStacktrace: true,
  })

  hotjar.initialize(HJID, HJSV)
}

console.log(`CityScour: Release ${RELEASE}`)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloService.getClient()}>
      <UserContextProvider>
        <UIProvider>
          <App />
        </UIProvider>
      </UserContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
  () => {
    SplashScreen.hide()
  }
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
